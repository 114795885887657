import React from "react";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

const Footer = (props) => {
  return (
    <footer>
      <Container>
        <Row className="footer-row">
          <Col md={3} sm={5} className="box">
            <div className="logo">
              <ion-icon name="bag"></ion-icon>
              <h1>
                {props.merchant?.profile?.store?.name ?? "Gravity Technologies"}
              </h1>
            </div>
            <p>
              {props.merchant?.details?.headings?.h1 ??
                `This site is powered by Gravity Technologies. You can also bring your business online and get started accepting orders and payments digitally. Start Now. `}
            </p>
          </Col>
          {/* <Col md={3} sm={5} className='box'>
              <h2>About Us</h2>
              <ul>
                <li>Careers</li>
                <li>Our Stores</li>
                <li>Our Cares</li>
                <li>Terms & Conditions</li>
                <li>Privacy Policy</li>
              </ul>
            </Col> */}
          {/* <Col md={3} sm={5} className='box'>
              <h2>Customer Care</h2>
              <ul>
                <li>Help Center </li>
                <li>How to Buy </li>
                <li>Track Your Order </li>
                <li>Corporate & Bulk Purchasing </li>
                <li>Returns & Refunds </li>
              </ul>
            </Col> */}
          <Col md={3} sm={5} className="box">
            <h2>Contact Us</h2>
            <ul>
              <li>
                {props.merchant?.profile?.store?.address ??
                  "305, Satguru Parinay, Indore, MP India"}
              </li>
              <a
                href={`mailto: ${
                  props.merchant?.profile?.personel?.email ??
                  "gravitytechnologies.online@gmail.com"
                }`}
                style={{ textDecoration: "none", color: "white" }}
              >
                <li>
                  Email:{" "}
                  {props.merchant?.profile?.personel?.email ??
                    "gravitytechnologies.online@gmail.com"}
                </li>
              </a>
              <a
                href={`tel: ${props.merchant?.phone ?? "8889875410"}`}
                style={{ textDecoration: "none", color: "white" }}
              >
                <li>Phone: +91{props.merchant.phone ?? "8889875410"}</li>
              </a>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <Link aria-label="" to={`/privacy`} style={{ textDecoration: "none", color: "white" }}>
              <h2>Privacy Policy</h2>
            </Link>
            <Link aria-label="" to={`/terms`} style={{ textDecoration: "none", color: "white" }}>
              <h2>Terms And Conditions</h2>
            </Link>
            <Link aria-label="" to={`/refund`} style={{ textDecoration: "none", color: "white" }}>
              <h2>Refund and Cancellation</h2>
            </Link>
            {/* <Link aria-label="" to={`/refund`} style={{ textDecoration: "none", color: "white" }}>
              <h2>Refund and cancellation policy</h2>
            </Link> */}
          </Col>
        </Row>
      </Container>
      {/* <p>Powered by Paygic</p> */}
    </footer>
  );
};

export default Footer;
