import React from "react";


const AntiMonyLaunderingPolicy = () =>{
return<>
<div style={{'backgroundColor': ' #fff'}}>
<div className="container-xl" >
    <br /><h2>Refund and Cancellation Policy</h2>
    <h3>1. Refund Policy</h3>
    <h5>No Refunds for Completed Services:</h5>
    <p> Once a service has been fully completed and delivered to the Client, no refunds will be issued.</p>
    <h5>Refunds for Uncompleted Services:</h5>
    <p>If Gravity Technologies is unable to complete a service due to reasons within its control, the Client may be eligible for a partial or full refund. The amount of the refund will be determined on a case-by-case basis based on the nature of the uncompleted service and the progress made.</p>
    <h5>Client-Initiated Cancellations:</h5>
    <p> If the Client cancels a service before it has begun, Gravity Technologies may retain a portion of the upfront payment as a cancellation fee. The amount of the cancellation fee will be determined based on the nature of the service and the stage of development.</p>
    <h3>2. Cancellation Policy</h3>
    <h5>Client-Initiated Cancellations:</h5>
    <p>The Client may cancel a service at any time by providing written notice to Gravity Technologies.</p>
    <h5>
    Gravity Technologies-Initiated Cancellations:
    </h5>
    <p>Gravity Technologies reserves the right to cancel a service for any reason, including but not limited to, the Client's breach of contract or failure to cooperate. In the event of a Gravity Technologies-initiated cancellation, Gravity Technologies will refund any prepaid fees to the Client, minus any applicable cancellation fees.</p>
    <h3>3. Force Majeure
    </h3>
    <p>Neither party shall be liable for any delay or failure to perform its obligations under this agreement due to causes beyond its reasonable control, including but not limited to acts of God, natural disasters,
    war, terrorism, or government actions.</p>
    <h3>4. Dispute Resolution</h3>
    <p>Any disputes arising under this agreement shall be resolved through negotiation or mediation. If the parties are unable to resolve the dispute through negotiation or mediation, the dispute shall be submitted to binding arbitration in [Jurisdiction].</p>
    <h3>5. Governing Law</h3>
    <p>This refund and cancellation policy shall be governed by and construed in accordance with the IT Act.</p>
    <h3>6. Timeframe for Refunds</h3>
    <p>Gravity Technologies will process all refunds within 15-20 business days from the date the cancellation request is received.
    </p>
    
    </div>
    </div>

</>

}; 


export default AntiMonyLaunderingPolicy;

