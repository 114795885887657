import { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Privacy from "./pages/privacyPolicy";
import Terms from "./pages/t&c";
import Refund from "./pages/antiMoneyLaunderingPolicy";
const Home = lazy(() => import("./pages/Home"));
const Shop = lazy(() => import("./pages/Shop"));
const Cart = lazy(() => import("./pages/Cart"));
const Product = lazy(() => import("./pages/Product"));
const Checkout = lazy(() => import("./pages/checkout.js"))
const PlaceOrder = lazy(() => import("./pages/placeOrder.jsx"))
const Order = lazy(() => import("./pages/history.js"))

function App() {
  


  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
       
        <Routes>
        <Route path="/" element={<Home/>} />
          <Route path="/:mid" element={<Home/>} />
          <Route path="/:mid/shop" element={<Shop />} />
          <Route path="/:mid/shop/:id" element={<Product />} />
          <Route path="/:mid/cart" element={<Cart />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/placeOrder/:id/:token" element={<PlaceOrder />} />
          <Route path="/order/:id/:token" element={<Order />} />

        </Routes>
        
      </Router>
    </Suspense>
  );
}

export default App;
