import React from "react";


const TermsCondition = () => {
    return <>
        <div style={{ 'backgroundColor': ' #fff', }}>
            <div className="container-xl" >
                <br /><h2>Terms & Conditions</h2>
                <h3>1. Scope of Services</h3>
                <p>Gravity Technologies (hereinafter referred to as "Gravity Technologies") agrees to provide the following services to the Client:</p>
                <h5>App Development: Development of a mobile application for [Platform(s)] based on the Client's specifications.</h5>
                <h5>Web Development: Development of a website based on the Client's specifications.</h5>
                <h5>Skill-Based Game Development: Development of a skill-based game based on the Client's specifications.</h5>
                <h3>2. Client's Obligations</h3>
                <p>The Client agrees to:

Provide all necessary information, assets, and materials required for the development of the project.
Review and approve project deliverables within the agreed-upon timeframe.
Pay all fees and charges as outlined in the project agreement.
Comply with all applicable laws and regulations.</p>
<h3>3. Project Timeline and Deliverables</h3>
<p>The estimated project timeline is [Timeline]. The project deliverables will include:

[List of deliverables, e.g., source code, design files, documentation]</p>
<h3>4. Intellectual Property</h3>
<p>Client's Intellectual Property: The Client retains ownership of all intellectual property rights related to the project, including trademarks, copyrights, and patents.
Gravity's Intellectual Property: Gravity retains ownership of all intellectual property rights related to its proprietary software and tools used in the project.
Work Product: Gravity will transfer ownership of the work product (e.g., the app, website, or game) to the Client upon full payment of the project fees.</p>
<h3>5. Fees and Payment</h3>
<p>Payment Schedule: [Outline the payment schedule, e.g., upfront payment, milestones, final payment]
Late Payment: If the Client fails to make a payment on time, Gravity may charge a late fee of [Percentage] per month.</p>
<h3>6. Confidentiality</h3>
<p>Both parties agree to maintain the confidentiality of any proprietary or confidential information disclosed to each other during the course of the project.   </p>
<h3>7. Termination</h3>
<p>Either party may terminate the agreement for cause if the other party materially breaches its obligations. In the event of termination, the Client shall pay Gravity for all services rendered up to the date of termination.</p>
<h3>8. Limitation of Liability</h3>
<p>In no event shall either party be liable to the other for any indirect, incidental, special, or consequential damages arising out of or in connection with this agreement.</p>
                



            </div>
        </div>
        <br />
    </>
}

export default TermsCondition;